
import { defineComponent, onMounted, ref } from 'vue'
const quarterList = ['Q1', 'Q2', 'Q3', 'Q4']
export default defineComponent({
    name: '',
    props: {
        rootFrom: {
            type: String,
            default: ''
        }
        
    },
    setup(props, context) {
        const yearList = ref<number[]>([])
        const valueYear = ref()
        for (let i = 0; i < 79; i++) {
          yearList.value.push(i + 2021)
        }
         

        // 定义季度变量
        const valueQuarter = ref()

        // 定义月份
        const valueMonth = ref('')
        const monthList = ref({})
        monthList.value['Q1'] = ['01', '02', '03']
        monthList.value['Q2'] = ['04', '05', '06']
        monthList.value['Q3'] = ['07', '08', '09']
        monthList.value['Q4'] = ['10', '11', '12']
        

        const handleChange = (type = '') => {
            if (type === 'quarter') {
                valueMonth.value = ''
            }
            if (props.rootFrom === 'estimatedPer') {
                context.emit('gettableper')
            }
        }
        onMounted(() => {
            if (props.rootFrom === 'estimatedPer') {
                valueYear.value = '2021'
                valueQuarter.value = 'Q1'
            } else {
                valueYear.value = ''
                valueQuarter.value = ''
            }
        })
        return {
            yearList,
            valueYear,

            quarterList,
            valueQuarter,

            monthList,
            valueMonth,
            handleChange,

        }

    }
 });
