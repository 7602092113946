import { fetchGet, fetchPost, fetchPostPic } from './index';
import { MaintainTablelProps, MaintainBrandProps, FaSettingTableProps } from '@/views/Finance/types';
interface FileProps {
  excelName: string;
  sourceFilePath: string;
}
// 获取 fa maintain的数据
export const getMaintainNormal = (params = {}): Promise<MaintainTablelProps> => {
  return fetchGet('/pgapi/prog/setting/fa/normal', params);
};

export const getMaintainRetention = (params = {}): Promise<MaintainTablelProps> => {
  return fetchGet('/pgapi/prog/setting/fa/retention', params);
};

// 获取 fa maintain 中 brand 下拉内容
export const getMaintainBrand = (params = {}): Promise<MaintainBrandProps> => {
  return fetchGet('/pgapi/prog/setting/fa/brand', params);
};

// 获取下载文件的列表
export const getFileList = (params = {}): Promise<FileProps[]> => {
  return fetchGet('pgapi/prog/setting/fa/getFileList', params);
};

// 获取 fa confirm 的数据  ---- confirm/re open
export const getCalculatorOperation = (params = {}): Promise<MaintainTablelProps> => {
  return fetchGet('/pgapi/prog/setting/fa/calculator', params);
};

// 更改 fa 状态
export const updateState = (configParams: any, params = {}) => {
  const { status, offer, offerType, brand, bu, customerType } = configParams;
  let saveStatus = '';
  if (status === 'unconfirm') {
    saveStatus = 'confirm';
  } else if (status === 'confirm') {
    saveStatus = 'reopen';
  } else if (status === 'reopen') {
    saveStatus = 'unconfirm';
  }
  return fetchPost(
    `/pgapi/prog/setting/fa/updateState?status=${saveStatus}&offer=${offer}&offerType=${offerType}&brand=${brand}&bu=${bu}&customerType=${customerType}`,
    params
  );
};

// 获取fa setting 的数据 初始化时
export const getmappedAll = (params = {}): Promise<FaSettingTableProps[]> => {
  return fetchGet('/pgapi/setting/commu_stting/findCommuSet', params);
};

// 保存fa setting 的配置
export const saveFaSetting = (
  brand: string,
  buId: string,
  sheetName: string,
  programId: string | number,
  tableId: string | number,
  params = {}
) => {
  return fetchPost(`pgapi/setting/commu_stting/${brand}/${buId}/${sheetName}/${programId}/${tableId}/save`, params);
};

// 查询version 信息
export const getVersionList = (params = {}) => {
  return fetchGet('/pgapi/prog/setting/fa/getVersionList', params);
};

// 查询版本对比信息
export const getVersionCompareList = (programId: string, bu: string, params = {}) => {
  return fetchPost(`/pgapi/prog/setting/fa/getVersionCompareList/${programId}/${bu}`, params);
};

// 查询百分比的数据 regular
export const getEstimatedPerReg = (params = {}): Promise<MaintainTablelProps> => {
  return fetchGet('/pgapi/prog/setting/fa/estimated_penetration_regular', params);
};

// 查询百分比的数据 retention
export const getEstimatedPerRet = (params = {}): Promise<MaintainTablelProps> => {
  return fetchGet('/pgapi//prog/setting/fa/estimated_penetration_retention', params);
};

// 上传excel文件
export const uploadPer = (params = {}) => {
  return fetchPost(`/pgapi/prog/setting/fa/upload_estimated_penetration`, params);
};

// 获取Customer Type
export const getCustomerType = (): Promise<any> => {
  return fetchGet('/pgapi/program/setting/get_customer_type');
};

export const getCalculatorMaintain = (params = {}): Promise<MaintainTablelProps> => {
  return fetchGet('/pgapi/prog/setting/fa/calculatorMaintain', params);
};

// 获取type class 和 model的关系
export const getSelectVehicle = (params = {}) => {
  return fetchGet('/pgapi/prog/setting/fa/selectVehicle', { params });
};

// 获取brand list
export const getBrandList = () => {
  const params = {
    property: 'brand'
  };
  return fetchGet('/vapi/get_property_lov', { params });
};

// 获取bu list
export const getAllBu = () => {
  return fetchGet('/usapi/users/bu/selected');
};

// 根据bu 获取brand
export const getBrandByBu = (params = {}) => {
  return fetchPost('/pgapi/program/design/search_eligible_vehicle', params);
};

// 获取typeclass model year的关系
export const getTypeModelYearList = (params = {}) => {
  return fetchGet('/pgapi/program_approval/selectVehicle', { params });
};

// oasis-25929 delete 计算器
export const deleteCalculator = (configParams: any, params = {}) => {
  const { offer, offerType, brand, bu, customerType } = configParams;
  return fetchPost(
    `/pgapi/prog/setting/fa/delete_calculator?offer=${offer}&offerType=${offerType}&brand=${brand}&bu=${bu}&customerType=${customerType}`,
    params
  );
};

export const uploadCalculator = (formData: FormData, params: any): Promise<any> => {
  return fetchPostPic(`/pgapi/prog/setting/fa/upload_calculator`, formData, {
    params
  });
};

export const validateCalculator = (formData: FormData, params: any): Promise<any> => {
  return fetchPost(`/pgapi/prog/setting/fa/validate_calculator`, formData, {
    params
  });
};
