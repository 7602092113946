
import { defineComponent, ref, onMounted, reactive, watch } from 'vue'
import { ColmunsProps, FileInfo, FileResponse } from '@/API/types'
import { BuItem, BrandItem } from '@/views/Approve/SalesRoleApprove/ProgramHome/types';
import { getBrand, getSubscribedBu } from "@/API/approve";
import { getEstimatedPerReg, getEstimatedPerRet, getCustomerType, getBrandList, getAllBu, getBrandByBu } from '@/API/finance'
import { MaintainCellProps, MaintainColProps } from './types'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue';
import SelectPeriod from './components/SelectPeriod.vue'
import useTableHeight from "@/hooks/useTableHeight";
import { baseURL } from '@/API'

interface BrandResponse {
    buId: string;
    buCode: string;
    buNameEN: string;
    buNameCN: string;
    brands: BrandItem[];
}
export default defineComponent({
    components: {
      SelectPeriod,
    },
    name: '',
    setup() {
        const { tableHeight } = useTableHeight(false);
        // 选择品牌
        const store = useStore()
       
        const visibleUpload = ref(false)

        const RefselectPeriod = ref()
       
        // 配置columns
        const columns = ref<ColmunsProps[]>([])
        const configColumns = (data: MaintainColProps[]) => {
          columns.value = []
          data.forEach((item: MaintainColProps, index: number) => {
            if (index === data.length - 1) {
              columns.value.push({
                title: item.display,
                dataIndex: item.id,
                key: item.id,
                slots: {customRender: 'status'}
              })
            } else {
              columns.value.push({
                title: item.display,
                dataIndex: item.id,
                key: item.id,
              })
            }
          }) 
        }

        const activeKey = ref('regular')
        // 表格的数据
        const dataSource = ref<MaintainCellProps[]>([])


         // 初始化数据
        const offerType = ref('fa')
        const brandsSelect = ref()
        const buSelect = ref()
        const buyBrandSelect = ref()
        const customerSelect = ref()
        const valueBrand = ref<string>('')
        const valueBu = ref<string>('')
        const valueCustomer = ref('')
        const valueMark = ref('')
        const markSelect = reactive(['Order Protection', 'Pre Sale'])

        // 获取下拉选择内容
        const getSelectData = async() => {
            // CustomerType
            customerSelect.value = await getCustomerType()
            // 获取brand list FA
            brandsSelect.value = await getBrandList()
            valueBrand.value = brandsSelect.value[0]

            // 获取bu 
            buSelect.value = await getAllBu()
        }

        // 获取表格数据
        const getTableData = () => {
          const params = {
              brand: valueBrand.value,
              year: RefselectPeriod.value.valueYear,
              quarter: RefselectPeriod.value.valueQuarter,
              month: RefselectPeriod.value.valueMonth,
              bu: valueBu.value,
              offerType: offerType.value,
              offer: valueMark.value,
              customerType: valueCustomer.value,
          }
          getEstimatedPerReg({params}).then((res) => {
            configColumns(res.maintain.tableCol)
            dataSource.value = res.maintain.tableCell
          })
        }
        const init = async() => {
            await getSelectData()
            // 获取列表
            getTableData()

        }

        // 查询计算器数据
        const onSearch = () => {
          getTableData()
        }

        // 下拉改变
        const handleChange = () => {
          getTableData()
        }

        const gettableper = () => {
          getTableData()
        }

        // 切换sheet
        const cutTabs = () => {
          if (activeKey.value === 'regular') {
            offerType.value = 'fa'
            valueBrand.value = brandsSelect.value[0]
          } else {
            valueBu.value = ''
            valueBrand.value = ''
            valueCustomer.value = ''
            valueMark.value = ''
            offerType.value = 'buydown'
            RefselectPeriod.value.valueYear = ''
          }
          getTableData()
          
        }

          // 切换BU
         const handleSelectBu = (value: string) => {
            valueBrand.value = ''
            buyBrandSelect.value = []
            // 根据bu 获取brand list
            const params = {
              bu: valueBu.value,
              target: "Vehicle.brand"
            }
            getBrandByBu(params).then((res: any) => {
              buyBrandSelect.value = res
            })
        }

        // 设置请求token
        const headers = {
          Authorization: store.state.Authorization
        }
        const uploadData = ref()
        
        const onUpload = () => {
          if (offerType.value !== 'fa') {
            if (!valueBu.value || !valueBrand.value) {
              message.error("Bu 和 brand是必选项")
              return false
            }
            if (valueCustomer.value || valueMark.value) {
              visibleUpload.value = true
            } else {
              message.error("Customer Type和Special Mark至少选一个")
            }
          } else {
            visibleUpload.value = true
          } 
          uploadData.value = {
              brand: valueBrand.value,
              year: RefselectPeriod.value.valueYear,
              quarter: RefselectPeriod.value.valueQuarter,
              month: RefselectPeriod.value.valueMonth,
              bu: valueBu.value,
              offerType: offerType.value,
              offer: valueMark.value,
              customerType: valueCustomer.value,
            }
        }

        // 上传文件
        const fileList = ref([])
        const uploadChange = (info: FileInfo) => {
          // 上传成功。。
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            getTableData()
          } else if (info.file.status === 'error') {
            // 上传失败。。
            if ((info.file.response as FileResponse).message.includes('column') || (info.file.response as FileResponse).message.includes('Regular Campaign'))  {
              message.error((info.file.response as FileResponse).message);
            } else {
              message.error(`Excel format and formula checking failed.`);
            }
            setTimeout(() => {
              fileList.value = []
              visibleUpload.value = false
            }, 100)
          }
        }

        const cancelDrawer = () => {
          visibleUpload.value = false
          fileList.value = []
        }

       watch(() => {
         return [valueCustomer.value , valueMark.value]
       }, ([newCustomer, newMark]) => {
          if (typeof newCustomer === 'undefined') {
            valueCustomer.value = ''
          }
          if (typeof newMark === 'undefined') {
            valueMark.value = ''
          }
       })

        onMounted(() => {
          init()
        })
         return {
            tableHeight,
            visibleUpload,
            RefselectPeriod,
            valueBrand,

            activeKey,
            cutTabs,
            columns,
            dataSource,

            handleChange,
            gettableper,

            headers,
            fileList,
            uploadChange,
            uploadData,
            onUpload,
            cancelDrawer,
            baseURL,

            onSearch,
            buSelect,
            brandsSelect,
            customerSelect,
            markSelect,
            buyBrandSelect,
            valueBu,
            valueMark,
            valueCustomer,
            handleSelectBu,
            offerType,
         }

    }
 });
